import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import cskh from 'assets/images/cskh.png';
import Laptop from 'assets/images/Laptop.png';
import legal from 'assets/images/legal.png';
import savemoney from 'assets/images/save-money.png';
import smart from 'assets/images/smart.png';
import tick from 'assets/images/tick.png';
import tryow from 'assets/images/tryow.png';
import { Images } from 'assets/images';
import Footer from 'components/Footer';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  styCardBenefit: {
    flexDirection: 'row',
    display: 'flex',
    width: '40%',
    background: '#cdeefa',
    padding: 40,
    borderTopLeftRadius: 50,
    borderBottomRightRadius: 50,
    margin: 20,
  },
  hedaer: {
    background: `url(${Images.background_banner_search}) `,
    backgroundRepeat: 'no-repeat',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '476px',
    marginTop: '0px',
  },
  styBtnOpt: {
    flexDirection: 'row',
    display: 'flex',
    backgroundColor: '#ff9b53',
    borderRadius: 20,
    padding: '0 12px',
    margin: '0 12px',
  },
}));

function SearchInvoice(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const renderBenefit = () => {
    return (
      <>
        <h2 style={{ textAlign: 'center' }}>{`LỢI ÍCH CỦA YOINVOICE`}</h2>
        <div style={{ flexDirection: 'column', display: 'flex', margin: 20 }}>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div className={classes.styCardBenefit}>
              <div style={{ paddingRight: 10 }}>
                <h3 className={classes.styTxtTitle}>{`TIẾT KIỆM CHI PHÍ`}</h3>
                <p className={classes.styTxtContent}>
                  {`Tiết kiệm 95% chi phí in ấn, vận chuyển, lưu trữ và bảo quản hóa đơn`}
                </p>
              </div>
              <img src={savemoney} alt={'logo YoyoPay'} style={{ width: 90, height: 90 }} />
            </div>
            <div className={classes.styCardBenefit}>
              <div style={{ paddingRight: 10 }}>
                <h3 className={classes.styTxtTitle}>{`TƯ VẤN CHUYÊN NGHIỆP`}</h3>
                <p className={classes.styTxtContent}>
                  {`Được tư vấn và hướng dẫn sử dụng bởi đội ngũ kế toán thuế giàu kinh nghiệm`}
                </p>
              </div>
              <img src={cskh} alt={'logo YoyoPay'} style={{ width: 90, height: 90 }} />
            </div>
          </div>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div className={classes.styCardBenefit}>
              <div style={{ paddingRight: 10 }}>
                <h3 className={classes.styTxtTitle}>{`PHÁP LÝ`}</h3>
                <p className={classes.styTxtContent}>
                  {`YOINVOICE được cơ quan thuế đánh giá cao về chất lượng và giới thiệu sử dụng`}
                </p>
              </div>
              <img src={legal} alt={'logo YoyoPay'} style={{ width: 90, height: 90 }} />
            </div>
            <div className={classes.styCardBenefit}>
              <div style={{ paddingRight: 10 }}>
                <h3 className={classes.styTxtTitle}>{`THÔNG MINH & LINH HOẠT`}</h3>
                <p className={classes.styTxtContent}>
                  {`Dễ dàng sử dụng, Phát hành hóa đơn mọi lúc mọi nơi, Báo cáo chi tiết thông minh`}
                </p>
              </div>
              <img src={smart} alt={'logo YoyoPay'} style={{ width: 90, height: 90 }} />
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <Box className={classes.hedaer}>
        <Container>
          <div>
            <h1 style={{ color: 'white', textAlign: 'center' }}>{`TRA CỨU HÓA ĐƠN ĐIỆN TỬ`}</h1>
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className={classes.styBtnOpt}>
                <p style={{ color: 'white', margin: 10 }}>{`TRA CỨU MÃ HOÁ ĐƠN`}</p>
              </div>
              <div className={classes.styBtnOpt}>
                <p style={{ color: 'white', margin: 10 }}>{`KIỂM TRA FILE XML`}</p>
              </div>
              <div className={classes.styBtnOpt}>
                <p style={{ color: 'white', margin: 10 }}>{`TRA CỨU DANH SÁCH HOÁ ĐƠN`}</p>
              </div>
            </div>
          </div>
        </Container>
      </Box>
      <Box pt={4}>
        <Container>{renderBenefit()}</Container>
      </Box>
      <Footer />
    </>
  );
}
SearchInvoice.propTypes = {};

export default SearchInvoice;
