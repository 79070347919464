import React from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { AppBar, Badge, Box, IconButton, Menu, MenuItem, Toolbar, Typography, Container } from '@material-ui/core';
import bgFooter from 'assets/images/background_footer.png'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${bgFooter})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 300
  },
  contain: {
    position: 'absolute',
    color: '#fff'
  },
}));
Footer.propTypes = {};

function Footer(props) {
  const classes = useStyles();

  return (
    <Box pt={4}>
        <div className={classes.root}>
          <div className={classes.contain} style={{paddingLeft: '40%'}}>
            <p style={{ fontSize: '20px'}}>
              Công ty cổ phần School Tech
            </p>
            <p style={{ fontSize: '15px'}}>
              49 Bàn Cờ, Phường 1, Quận 3, TPHCM
            </p>
          </div>
        </div>
    </Box>
  );
}

export default Footer;
