import styled from 'styled-components';
export const CoverCard = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 2rem;
  padding: 20px;
`;

export const Button = styled.div`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  background-color: #cdeefa;
  border-radius: 30px;
  padding: 12px 50px;
`;

export const CoverButton = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
`;

export const TxtPaynow = styled.h4`
  color: white;
  margin: 12px;
  text-align: center;
  font-weight: 700;
`;

export const TxtDis = styled.p`
  margin-top: 0px;
  margin-left: 12px;
  line-height: 1.6;
  font-size: 18px;
  text-align: justify;
`;

export const CoverLine = styled.div`
  flex-direction: row;
  display: flex;
`;
