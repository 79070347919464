import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NewsHome from './pages/NewsHome';
import DetailNews from './pages/DetailNews';
NewsFeature.propTypes = {};

function NewsFeature(props) {
  const match = useRouteMatch();
  console.log('match.url', match.url);
  return (
    <Box pt={4}>
      <Switch>
        <Route path={match.url} exact component={NewsHome} />
      </Switch>
    </Box>
  );
}

export default NewsFeature;
