import { AppBar, Box, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logoYoyopay from 'assets/images/logoYoyopay.png';
import bgHeader from 'assets/images/background_header.png';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { menuHeader } from 'constants/dataCommon';
const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundImage:  `url(${bgHeader})`,
    height: 70,
    boxShadow:
      ' 0 1px 2px rgba(0,0,0,0.02),0 2px 4px rgba(0,0,0,0.02),0 4px 8px rgba(0,0,0,0.02),0 8px 16px rgba(0,0,0,0.02),0 16px 32px rgba(0,0,0,0.02),0 32px 64px rgba(0,0,0,0.02)',
  },
  contain: {
    maxWidth: 1300,
    width: '100%',
  },

  menuListUl: {
    listStyleType: 'none',
    textAlign: 'center',
    margin: theme.spacing(0, 0),
    flex: 1,
  },
  menuListLi: {
    display: 'inline-block',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
  },
  buttonMenu: {
    padding: theme.spacing(1, 4),
    backgroundColor: 'transparent',
    '&:hover': {
      color: '#fff',
    },
    borderWidth: 0,
    borderRadius: 4,
    fontWeight: 600,
    fontSize: 14,
  },
}));
const Header = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [menuSel, setMenuSel] = useState();

  return (
    <Box style={{ position: 'sticky', top: 0 }}>
      <AppBar position="static" className={classes.root}>
        <Toolbar className={classes.contain}>
          <button
            className={classes.buttonMenu}
            onClick={(e) => {
              history.push('/');
              setMenuSel('');
            }}
          >
            <img src={logoYoyopay} alt={'logo YoyoPay'} style={{ width: 70, height: 70 }} />
          </button>
          <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <ul className={classes.menuListUl}>
              {menuHeader.map((item, index) => {
                return (
                  <li className={classes.menuListLi} key={index}>
                    <div
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center' }}
                    >
                      <button
                        className={classes.buttonMenu}
                        style={
                          menuSel?.name === item?.name
                            ? {
                                color: '#fff',
                                fontWeight: 700,
                              }
                            : {}
                        }
                        onClick={(e) => {
                          if (item.path !== '') {
                            setMenuSel(item);
                            history.push(item.path);
                          } else {
                            enqueueSnackbar('Chức năng đang được cập nhật vui lòng thử lại sau', {
                              variant: 'warning',
                            });
                          }
                        }}
                      >
                        {item.name}
                      </button>
                      {menuSel?.name === item?.name && <div style={{ width: 70, height: 3, background: '#45baca' }} />}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Header;
