import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import imgNews from 'assets/images/imgNews.png';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({}));

function NewsHome(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <>
    <Box pt={4}>
      <Container>
        <div
          onClick={() => {
            history.push('/news/detailNews');
          }}
          style={{ width: '100%', height: 400, display: 'flex', flexDirection: 'row' }}
        >
          <div style={{ width: '60%' }}>
            <img src={imgNews} alt={'logo YoyoPay'} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
          <div style={{ width: '40%', background: '#E6F2F4', padding: 20 }}>
            <h3>{`Cập nhật phần mềm YoInvoice theo Thông tư 78/2021/TT-BTC`}</h3>
            <p style={{ textAlign: 'justify' }}>
              {`Ngày 17/9/2021, Bộ Tài chính ban hành Thông tư 78/2021/TT-BTC, hướng dẫn việc triển khai hóa đơn điện tử, chứng từ điện tử theo Luật Quản lý thuế số 38, Nghị định 123/2020/NĐ-CP. Theo đó, kể từ ngày 1/7/2022, hóa đơn giấy sẽ không còn hiệu lực, thay vào đó là áp dụng hóa đơn điện tử. Tuy nhiên trong Thông tư có nêu rõ việc khuyến khích các cơ quan, tổ chức, doanh nghiệp đã đáp ứng đủ điều kiện về hạ tầng công nghệ thông tin áp dụng hóa đơn điện tử trước ngày 1/7/2022....`}
            </p>
            <h4 style={{ color: '#3cb6c8', textDecoration: 'underline' }}>{`Xem thêm`}</h4>
          </div>
        </div>
      </Container>
    </Box>
    </>
  );
}
NewsHome.propTypes = {};

export default NewsHome;
