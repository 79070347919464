export const Images = {
  logoYoyoyay: require('./logoYoyopay.png'),

  LogoFacebook: require('./icon_facebook.png'),
  LogoSkype: require('./icon_skype.png'),
  LogoSkyper: require('./icon_skyper.png'),
  LogoCall: require('./logo-call.png'),
  LogoZalo: require('./zalo-logo.png'),
  background_price: require('./background_price.png'),
  background_feature: require('./background_feature.png'),
  img_einvoice_major: require('./img_einvoice_major.png'),
  create_invoice: require('./create_invoice.png'),
  bg_banner_cooperate: require('./bg-banner-cooperate.png'),
  Laptop: require('./Laptop.png'),
  background_cooperate_01: require('./background_cooperate_01.png'),
  background_cooperate_02: require('./background_cooperate_02.png'),
  background_cooperate_03: require('./background_cooperate_03.png'),
  background_banner_search: require('./background-banner-search.png'),
};
