import Footer from 'components/Footer';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import imgNews from 'assets/images/imgNews.png';

const useStyles = makeStyles((theme) => ({}));

function DetailNews(props) {
  return (
    <>
      <Box pt={4} style={{ background: 'white' }}>
        <Container>
          <h2>{`Cập nhật phần mềm YoInvoice theo Thông tư 78/2021/TT-BTC`}</h2>
          <p
            style={{ textAlign: 'justify' }}
          >{`   Ngày 17/9/2021, Bộ Tài chính ban hành Thông tư 78/2021/TT-BTC, hướng dẫn việc triển khai hóa đơn điện tử, chứng từ điện tử theo Luật Quản lý thuế số 38, Nghị định 123/2020/NĐ-CP. Theo đó, kể từ ngày 1/7/2022, hóa đơn giấy sẽ không còn hiệu lực, thay vào đó là áp dụng hóa đơn điện tử. Tuy nhiên trong Thông tư có nêu rõ việc khuyến khích các cơ quan, tổ chức, doanh nghiệp đã đáp ứng đủ điều kiện về hạ tầng công nghệ thông tin áp dụng hóa đơn điện tử trước ngày 1/7/2022.`}</p>
          <img src={imgNews} alt={'logo YoyoPay'} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          <h3>{`1. Quy định mới về ký hiệu mẫu số, hóa đơn điện tử`}</h3>
          <h4>{`Về mẫu số hóa đơn điện tử, tại Khoản 1, Điều 4, Thông tư 78/2021/TT-BTC, ký hiệu mẫu số hóa đơn có một chữ số tự nhiên 1, 2, 3, 4, 5, 6 thể hiện loại hóa đơn điện tử:`}</h4>
          <p>
            – Số 1: Hóa đơn điện tử giá trị gia tăng.
            <br />– Số 2: Hóa đơn điện tử bán hàng.
            <br />– Số 3: Hóa đơn điện tử bán tài sản công.
            <br />– Số 4: Hóa đơn điện tử bán hàng dự trữ quốc gia.
            <br />– Số 5: Tem/vé/thẻ/phiếu thu điện tử, các chứng từ có tên gọi khác nhưng có nội dung của hóa đơn điện
            tử.
            <br />– Số 6: Phiếu xuất kho kiêm vận chuyển nội bộ điện tử, phiếu xuất kho hàng gửi bán các đại lý điện tử.
          </p>
          <h4>{`Về ký hiệu hóa đơn điện tử, Thông tư quy định ký hiệu hóa đơn điện tử gồm nhóm 6 chữ số chữ viết và chữ số:`}</h4>
          <p>
            – Ký tự đầu tiên: C (hóa đơn điện tử có mã của cơ quan thuế) hoặc K (hóa đơn điện tử không có mã của cơ quan
            thuế).
            <br />– Hai ký tự tiếp theo: 2 chữ số Ả rập thể hiện năm lập hóa đơn điện tử.
            <br />– Một ký tự tiếp theo: T, D, L, M, N, B, G, H thể hiện loại hóa đơn điện tử:
            <br />+ Chữ T: Hóa đơn điện tử do doanh nghiệp, tổ chức, hộ cá nhân kinh doanh đăng ký sử dụng với cơ quan
            thuế.
            <br />+ Chữ D: Hóa đơn bán tài sản công và hóa đơn bán hàng dự trữ quốc gia hoặc hóa đơn điện tử đặc thù.
            <br />+ Chữ L: Hoá đơn điện tử của cơ quan thuế cấp theo từng lần phát sinh.
            <br />+ Chữ M: Hóa đơn điện tử khởi tạo từ máy tính tiền.
            <br />+ Chữ N: Phiếu xuất kho kiêm vận chuyển nội bộ điện tử.
            <br />+ Chữ B: Phiếu xuất kho hàng gửi bán đại lý điện tử.
            <br />+ Chữ G: Tem, vé, thẻ điện tử là hóa đơn giá trị gia tăng.
            <br />+ Chữ H: Tem, vé, thẻ điện tử là hóa đơn bán hàng.
            <br />– Hai ký tự cuối: Người bán tự xác định theo nhu cầu quản lý.
          </p>
          <h4>{`Như vậy, so với Thông tư 68/2019/TT-BTC, Thông tư 78/2021/TT-BTC đã có quy định cụ thể về hóa đơn điện tử bán tài sản công, hóa đơn điện tử bán hàng dự trữ quốc gia.`}</h4>
          <h3>{`2. Ủy nhiệm lập hóa đơn điện tử`}</h3>
          <p>
            Người bán hàng hóa, cung cấp dịch vụ là doanh nghiệp, tổ chức kinh tế, tổ chức khác được quyền ủy nhiệm cho
            bên thứ ba lập hóa đơn điện tử. Bên thứ ba là bên có quan hệ liên kết với người bán, là đối tượng đủ điều
            kiện sử dụng hoá đơn điện tử và không thuộc trường hợp ngừng sử dụng hóa đơn điện tử. Việc ủy nhiệm phải
            được lập bằng văn bản (hợp đồng hoặc thỏa thuận) và phải thể hiện các thông tin:
            <br />– Thông tin về bên ủy nhiệm và bên nhận ủy nhiệm (tên, địa chỉ, mã số thuế, chứng thư số);
            <br />– Thông tin về hoá đơn điện tử ủy nhiệm (loại hoá đơn, ký hiệu mẫu số hoá đơn, ký hiệu hóa đơn);
            <br />– Mục đích ủy nhiệm, thời hạn ủy nhiệm và phương thức thanh toán hoá đơn ủy nhiệm (ghi rõ trách nhiệm
            thanh toán tiền hàng hóa, dịch vụ trên hóa đơn ủy nhiệm);
            <br />– Bên ủy nhiệm và bên nhận ủy nhiệm phải thông báo cho cơ quan Thuế khi đăng ký sử dụng hóa đơn điện
            tử.
          </p>
          <h3>{`3. Thời điểm lập hóa đơn đối với dịch vụ ngân hàng`}</h3>
          <p>
            Trường hợp cung cấp dịch vụ ngân hàng: Ngày lập hóa đơn thực hiện định kỳ theo hợp đồng giữa hai bên kèm
            bảng kê hoặc chứng từ khác có xác nhận của hai bên, nhưng chậm nhất là ngày cuối cùng của tháng phát sinh
            hoạt động cung cấp dịch vụ;
            <br />
            Trường hợp cung cấp dịch vụ ngân hàng với số lượng lớn, phát sinh thường xuyên, cần có thời gian đối soát dữ
            liệu giữa ngân hàng và các bên thứ ba có liên quan (tổ chức thanh toán, tổ chức thẻ quốc tế hoặc các tổ chức
            khác), thời điểm lập hóa đơn là thời điểm hoàn thành việc đối soát dữ liệu giữa các bên, nhưng chậm nhất
            không quá ngày 10 của tháng sau tháng phát sinh.
          </p>
          <h3>{`4. Hóa đơn điện tử khởi tạo từ máy tính tiền`}</h3>
          <p>
            – Nguyên tắc: Phải có kết nối chuyển dữ liệu điện tử với cơ quan thuế.
            <br />– Đối tượng áp dụng:
            <br />
            Doanh nghiệp, hộ, cá nhân kinh doanh nộp thuế theo phương pháp kê khai theo mô hình kinh doanh: trung tâm
            thương mại, siêu thị, bán lẻ hàng tiêu dùng, ăn uống, nhà hàng, khách sạn, bán lẻ thuốc tân dược dịch vụ vui
            chơi, giải trí và các dịch vụ khác.
            <br />– Nội dung:
            <br />+ Tên, địa chỉ, mã số thuế người bán; Thông tin người mua (mã số định danh cá nhân hoặc mã số thuế)
            nếu người mua yêu cầu;
            <br />+ Tên hàng hóa, dịch vụ, đơn giá, số lượng, giá thanh toán.
            <br />
            Nếu tổ chức, doanh nghiệp nộp thuế theo phương pháp khấu trừ phải ghi rõ: giá bán chưa thuế GTGT, thuế suất
            thuế GTGT, tiền thuế GTGT, tổng tiền thanh toán có thuế GTGT, thời điểm lập hóa đơn, mã của cơ quan thuế.
          </p>
          <h3>{`5. Xử lý hóa đơn điện tử đã gửi cơ quan thuế có sai sót`}</h3>
          <p>
            – Trường hợp hóa đơn điện tử đã lập có sai sót phải cấp lại mã của cơ quan thuế, hoặc có sai sót cần xử lý
            theo hình thức điều chỉnh hoặc thay thế thì người bán có thể thông báo điều chỉnh cho từng hóa đơn, hoặc cho
            nhiều hóa đơn đến cơ quan thuế, chậm nhất là ngày cuối cùng của kỳ kê khai thuế GTGT phát sinh hóa đơn điện
            tử điều chỉnh;
            <br />– Trường hợp hóa đơn điện tử đã lập có sai sót và người bán đã xử lý điều chỉnh hoặc thay thế, sau đó
            lại phát hiện hóa đơn đó sai sót thì các lần xử lý tiếp theo thực hiện như hình thức đã áp dụng lần đầu.
            <br />– Sau thời hạn chuyển bảng tổng hợp dữ liệu hóa đơn điện tử đến cơ quan thuế, nếu bảng tổng hợp dữ
            liệu hóa đơn điện tử thiếu dữ liệu thì người bản gửi bảng bổ sung; nếu bảng tổng hợp dữ liệu có sai sót thì
            thông tin điều chỉnh cho các thông tin đã kê khai trên bảng tổng hợp.
            <br />– Trường hợp lập hóa đơn khi thu tiền trước hoặc trong khi cung cấp dịch vụ, sau đó phát sinh việc hủy
            hoặc chấm dứt cung cấp dịch vụ thì người bán hủy hóa đơn điện tử đã lập và thông báo với cơ quan thuế về
            việc hủy hóa đơn.
            <br />– Trường hợp theo quy định hóa đơn điện tử được lập không có ký hiệu mẫu số hóa đơn, ký hiệu hóa đơn,
            số hóa đơn có sai sót thì người bán chỉ thực hiện điều chỉnh mà không thực hiện hủy hoặc thay thế.
            <br />– Đối với nội dung về giá trị trên hóa đơn điện tử có sai sót thì: điều chỉnh tăng (ghi dấu dương),
            điều chỉnh giảm (ghi dấu âm) đúng với thực tế điều chỉnh.
          </p>
          <h4>{`Ngoài ra, để được tư vấn thêm về phần mềm hóa đơn điện tử YoInvoice, Quý doanh nghiệp vui lòng liên hệ:`}</h4>
        </Container>
      </Box>
      <Footer />
    </>
  );
}
DetailNews.propTypes = {};

export default DetailNews;
