import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Images } from 'assets/images';
import imgNews from 'assets/images/imgNews.png';
import { useHistory } from 'react-router-dom';
import { lstPrice } from 'constants/dataCommon';
import { CoverCard, Button, CoverButton, TxtPaynow } from './Price.styles';
import Footer from 'components/Footer';

const useStyles = makeStyles((theme) => ({}));

function PriceHome(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const renderAdvantages = () => {
    return (
      <div style={{ flexDirection: 'row', display: 'flex', padding: '20px 0px' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h1
            style={{ textAlign: 'left', width: '80%', fontSize: 36, lineHeight: '2' }}
          >{`BÁO GIÁ PHẦN MỀM HOÁ ĐƠN ĐIỆN THỬ YOINVOICE`}</h1>
        </div>
        <img src={Images.background_price} alt={'logo YoyoPay'} style={{ width: '55%' }} />
      </div>
    );
  };

  return (
    <>
      <Box pt={4} style={{ background: '#cdeefa' }}>
        <Container>{renderAdvantages()}</Container>
      </Box>
      <h2
        style={{ textAlign: 'center', color: '#3cb6c8', marginTop: 30 }}
      >{`BẢNG BÁO GIÁ HÓA ĐƠN ĐIỆN TỬ YOINVOICE`}</h2>
      <h3 style={{ textAlign: 'center' }}>{`Phí khởi tạo giảm còn 500.000 VND/thuê bao sử dụng chính thức`}</h3>
      <Box pt={4}>
        <Container>
          <CoverCard>
            {lstPrice.map((price, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  background: '#F3F8F9',
                  borderRadius: 12,
                  padding: 20,
                  margin: 20,
                }}
              >
                <h1 style={{ margin: 8, fontWeight: 800, color: '#45bac9' }}>{`${price.name}`}</h1>
                <p style={{ margin: 0, fontWeight: '500' }}>{`${price.dis}`}</p>
                <div style={{ width: '100%', height: 1, background: '#cacaca', margin: '8px 0' }} />
                <h1 style={{ margin: 8 }}>{`${price.price}`}</h1>
                <h2 style={{ margin: 8, fontSize: 18 }}>{`VNĐ/GÓI`}</h2>
                <div
                  onClick={() => {
                    document.documentElement.scrollIntoView({ behavior: 'smooth' });
                  }}
                  style={{ backgroundColor: '#ff9b53', width: '100%', borderRadius: 6, marginTop: 12 }}
                >
                  <TxtPaynow>{`MUA NGAY`}</TxtPaynow>
                </div>
              </div>
            ))}
          </CoverCard>
        </Container>
      </Box>
      <h3 style={{ textAlign: 'center', color: 'red' }}>{`Trên 20.000 hóa đơn, đồng giá 280 đồng/ hóa đơn`}</h3>
      <CoverButton>
        <Button>
          LIÊN HỆ ĐỂ MUA HÓA ĐƠN NHANH NHẤT <samp style={{ color: 'red' }}>1900 6474</samp>
        </Button>
      </CoverButton>
      <Footer />
    </>
  );
}
PriceHome.propTypes = {};

export default PriceHome;
