import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cskh from 'assets/images/cskh.png';
import transfer from 'assets/images/transfer.png';
import withdraw from 'assets/images/withdraw.png';
import charge from 'assets/images/charge.png';
import payment from 'assets/images/payment.png';
import schoolfee from 'assets/images/schoolfee.png';
import tryow from 'assets/images/tryow.png';
import React from 'react';
import InputCom from 'components/InputCom';
import ButtonCom from 'components/Buttom';
import Footer from 'components/Footer';


import bgTop from 'assets/images/bgTop.png';
import { FaCentercode } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  styTxtContent: {
    fontSize: 14,
    marginTop: 0,
    fontWeight: 600,
  },
  styTxtTitle: {
    fontWeight: 800,
    marginTop: 0,
  },
  styCardBenefit: {
    flexDirection: 'row',
    display: 'flex',
    width: '40%',
    background: '#cdeefa',
    padding: 40,
    borderTopLeftRadius: 50,
    borderBottomRightRadius: 50,
    margin: 20,
  },
}));

function Home(props) {
  const classes = useStyles();

  const renderAdvantages = () => {
    return (
      <>
        <div style={{ flexDirection: 'row', display: 'flex', height: 800}}>
          
        </div>
      </>
    );
  };
  const renderBenefit = () => {
    return (
      <>
        <div style={{ flexDirection: 'column', display: 'flex'}}>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div>
              <div style={{ textAlign: 'center', width: 120 }}>
              <img src={transfer} alt={'Logo transfer'} style={{ width: 100, height: 100 }} />
                <p style={{fontWeight: 'bold'}}>
                  {`CHUYỂN TIỀN`}
                </p>
              </div>
            </div>
            <div>
            <div style={{ textAlign: 'center', paddingLeft: 200 }}>
              <img src={withdraw} alt={'Logo withdraw'} style={{ width: 100, height: 100 }} />
                <p style={{fontWeight: 'bold'}}>
                  {`RÚT TIỀN`}
                </p>
              </div>
            </div>
            <div>
            <div style={{ textAlign: 'center', paddingLeft: 200 }}>
              <img src={charge} alt={'Logo charge'} style={{ width: 100, height: 100 }} />
                <p style={{fontWeight: 'bold'}}>
                  {`NẠP TIỀN`}
                </p>
              </div>
            </div>
            <div>
            <div style={{ textAlign: 'center', paddingLeft: 200, width: 120 }}>
              <img src={payment} alt={'Logo payment'} style={{ width: 100, height: 100 }} />
                <p style={{fontWeight: 'bold'}}>
                  {`THANH TOÁN`}
                </p>
              </div>
            </div>
            <div>
            <div style={{ textAlign: 'center', paddingLeft: 200 }}>
              <img src={schoolfee} alt={'Logo schoolfee'} style={{ width: 100, height: 100 }} />
                <p style={{fontWeight: 'bold'}}>
                  {`HỌC PHÍ YOYOSCHOOL`}
                </p>
              </div>
            </div>

          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <Box pt={4} style={{  backgroundImage: `url(${bgTop})` }}>
        <Container>{renderAdvantages()}</Container>
      </Box>
      <Box pt={4} style={{paddingTop: 100, paddingBottom: 100}}>
        <Container>{renderBenefit()}</Container>
      </Box>
      <Footer />
    </>
  );
}
Home.propTypes = {};

export default Home;
